<template>
  <div>
    <!--background-color="#0a1928"  -->
    <el-menu :collapse="isCollapse"
             unique-opened
             :default-active="$route.path"
             class="el-menu-vertical-demo menu_hidden_scroll"
             text-color="white">
      <div class="logo">
        <img src="@/assets/homepage/logo.png"
             alt="">
      </div>
      <div v-for="item in menus"
           style="overflow:hidden"
           :key="item.meta.name">
        <menuGroup v-if="item.children"
                   :group="item" />
        <MenuItem :item="item"
                  v-else />
      </div>
    </el-menu>
  </div>
</template>

<script>
import menuGroup from './menuGroup.vue'
import MenuItem from './menuItem.vue'
export default {
  name: 'Menu',
  props: ['menus'],
  components: {
    menuGroup, MenuItem,
  },
  data () {
    return {
      isCollapse: false,
      width: '215px',
      theme: {},
    }
  },
  created () {
    // if (window.localStorage.getItem('theme')) {
    //   let theme = JSON.parse(window.localStorage.getItem('theme'))
    //   this.$store.commit('SET_THEME', theme)
    //   this.theme = theme
    // } else {
    //   window.localStorage.setItem('theme', JSON.stringify(this.$store.state.theme))
    //   this.theme = this.$store.state.theme
    // }
  },
  mounted () {
    // this.menus = this.$router.options.routes
    // console.log('routes', this.menus);
  },
  methods: {
    show () {
      this.isCollapse = !this.isCollapse
      if (this.$parent.width == '215px') {
        setTimeout(() => {
          this.$parent.width = '64px'
          this.width = '64px'
        }, 250)
      } else {
        this.$parent.width = '215px'
        this.width = '215px'
      }
    },
    toAbout () {
      this.$router.push('/about')
    },
  }
}
</script>

<style lang="scss" scoped>
.el-menu-item-group__title {
  padding: 0;
}

.logo {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  img {
    transform: scale(0.9);
    margin-left: -30px;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 215px;
  min-height: 400px;
}

/*隐藏文字*/
::v-deep .el-menu--collapse .el-submenu__title span {
  display: none;
}
/*隐藏 > */
::v-deep .el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}

::v-deep .el-submenu .el-menu-item {
  background: #89c4ff;
}

::v-deep .left_menu_icon {
  margin-right: 10px;
}

::v-deep .el-submenu__title i {
  color: white;
}
.menu_hidden_scroll {
  height: 100vh;
  overflow: auto;
}
::v-deep .el-menu {
  background: #559de7;
}
</style>
<template>
  <div class="home">
    <div class="home_left"
         :style="{'width':width,}">
      <Menu ref="MenuRef"
            :menus="menus" />
    </div>
    <div class="home_right"
         :style="{'width':`calc(100vw - ${width}`}"
         id="view_warp">
      <div class="top_nav"
           :style="{'background':$store.state.theme.navBarColor,'width':`calc(100vw - ${width} `}">
        <div class="left_icon">
          <i @click="show"
             v-if="width == '215px'"
             class="el-icon-s-fold"></i>
          <i @click="show"
             v-else
             class="el-icon-s-unfold"></i>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/homepage' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item v-for="(item,index) in $store.state.navigation"
                                @click.native.prevent="powerCut(item,index)"
                                :key="index"> {{item.meta.title}} </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="right_select">
          <el-dropdown>
            <span class="el-dropdown-link">
              <el-avatar :size="35"
                         :src="circleUrl"></el-avatar>
              <span style="cursor: pointer;">{{username}}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item @click.native.prevent="editPassword">修改密码</el-dropdown-item> -->
              <!-- <el-dropdown-item @click.native.prevent="open">关于</el-dropdown-item> -->
              <!-- <el-dropdown-item @click.native.prevent="theme">主题颜色</el-dropdown-item> -->
              <el-dropdown-item @click.native.prevent="logOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!-- <div class="tags">
        <div v-for="(tag,index) in tags"
             @click="toPage(tag)"
             :key="index">
          <el-tag @close="clickClear(index)"
                  closable>
            {{tag.meta.title}}
          </el-tag>
        </div>
      </div> -->
      <div style="height:60px;width:100%">
      </div>
      <div class="view_warp">
        <transition mode="out-in">
          <router-view></router-view>
        </transition>
      </div>
    </div>
    <!-- <Theme ref="ThemeRef" /> -->
    <el-dialog title="修改密码"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="100px"
               ref="FormRef"
               :rules="rules">
        <el-row>
          <el-form-item label="输入新密码"
                        prop="password">
            <el-input clearable
                      placeholder="密码"
                      v-model="form.password"
                      type="password" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="确认密码"
                        prop="password_again">
            <el-input clearable
                      placeholder="密码"
                      v-model="form.password_again"
                      type="password" />
          </el-form-item>
        </el-row>
      </el-form>
      <el-row style="text-align:center">
        <el-button type="primary"
                   @click="submit">确定</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import Menu from '@/components/menu.vue'
// import Theme from '@/components/theme/backgroundColor.vue'


export default {
  name: 'Home',
  components: {
    Menu,
  },
  created () {
    let routes = this.$router.options.routes
    this.menus = routes.find(item => item.path == '/index').children
  },
  data () {
    var password_again1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password_again) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      form: {
        password: '',
        password_again: ''
      },
      rules: {
        password_again: [
          { validator: password_again1, required: true, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 10, message: '长度在 6 到 10 个字符', trigger: 'blur' }
        ],

      },
      width: '215px',
      menus: [],
      dialogVisible: false,
      username: window.localStorage.getItem('userName'),
      tags: [],
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
    }
  },
  methods: {
    show () {
      this.$refs.MenuRef.show()
    },
    clickClear (index) {
      this.$store.commit('DEL_TAG', index)
    },
    logOut () {
      window.localStorage.clear()
      window.sessionStorage.clear()
      this.$router.push('/login')

    },
    theme () {
      this.$refs.ThemeRef.drawer = true
    },
    toPage (item) {
      if (this.$route.path == item.path) {
        return
      }
      this.$router.push(item)
    },
    open () {
      // console.log('456s');
      // this.$alert('这是一段内容', '标题名称', {
      //   confirmButtonText: '确定',
      //   callback: action => {
      //     this.$message({
      //       type: 'info',
      //       message: `action: ${action}`
      //     });
      //   }
      // });
    },
    editPassword () {
      this.dialogVisible = true
    },
    submit () {
      this.$refs.FormRef.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v1/user/edit',
          method: 'post',
          data: this.form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.handleClose()
          this.logOut()
        })
      })
    },
    handleClose () {
      this.$refs.FormRef.resetFields()
      this.dialogVisible = false
    },
    powerCut (item, index) {
      if (this.$store.state.navigation.length - 1 == index) {
        return
      }
      this.$router.push({ path: item.path })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  overflow-x: hidden !important;

  display: flex;
  position: relative;
  .home_left {
    background: #3f9eff;
    transition: all 0.5s ease;
  }
  .home_right {
    background: #f6f6f6;
    height: 100vh;
    overflow-y: auto;
    .top_nav {
      width: 100%;
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      z-index: 2000;
      position: fixed;
      top: 0;
      .left_icon {
        flex: 1;
        i {
          cursor: pointer;
          font-size: 20px;
        }
      }
      .right_select {
        flex: 1;
        width: 100%;
        text-align: right;
      }
    }
  }
}
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.el-tag {
  margin: 5px 5px;
}
.el-dropdown-link {
  display: flex;
  align-items: center;
}
.el-dropdown {
  margin-right: 30px;
}
.el-avatar {
  margin: 0 5px;
}
.tags {
  width: 100%;
  overflow: auto;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  padding: 3px 0;
  & ::v-deep .el-tag {
    box-shadow: 1px 1px 1px 1px #cebcbc;
    cursor: pointer;
  }
  .el-tag:hover {
    position: relative;
    left: 1px;
    bottom: 1px;
  }
}

::v-deep .el-menu {
  border-right: none;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

::v-deep .el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: 5px 8px 12px 0 #e6e6e6;
}

.view_warp {
  min-height: calc(100vh - 130px) !important;
  margin: 15px;
  // padding: 20px;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0 !important;
  position: relative;
  // background: white;
  // background: white;
  border-radius: 10px;
  overflow: hidden;
}
::v-deep .el-table thead {
  color: black !important;
}

::v-deep .el-table--border th,
.el-table__fixed-right-patch {
  background: #f2f2f2 !important;
}

::v-deep .el-breadcrumb {
  display: inline-block;
}

::v-deep .el-form-item__label {
  font-size: 15px !important;
}

::v-deep
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped.current-row
  td {
  background: #d8eaff !important;
}
::v-deep .el-table__body tr.current-row > td {
  background: #d8eaff !important;
}

::v-deep .el-loading-mask {
  z-index: 2100;
}

::v-deep .edui-default .edui-toolbar .edui-combox .edui-combox-body {
  max-height: 20px;
}
::v-deep .edui-default .edui-button-body,
.edui-splitbutton-body,
.edui-menubutton-body,
.edui-combox-body {
  max-height: 20px;
}
/*图片预览 缩略图*/
.preview figure {
  float: left;
  width: 30%;
  height: calc(30vw - 0px);
  margin: 1.5%;
}

.preview figure img {
  width: 100%;
}

::v-deep .el-button {
  padding: 0;
  width: 90px;
  height: 36px;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
// 面包屑
.el-breadcrumb {
  margin-left: 15px;
  font-size: 15px;

  ::v-deep .el-breadcrumb__inner {
    &:hover {
      color: #409eff;
      cursor: pointer;
    }
  }
}

::v-deep .el-loading-spinner .el-loading-text {
  font-size: 16px;
}

::v-deep .el-loading-spinner i {
  font-size: 39px;
  color: #409eff;
}
</style>